import {useEffect, useState} from 'react'
import RXRIcon from '../RXRIcon'
import {makeStyles} from '@mui/styles'
import PropTypes from 'prop-types'
import useResidentLookup from '../hooks/useResidentLookup'
import {RESIDENT_STANDING_DELINQUENT, RESIDENT_STANDING_WARNING} from '../../constants/AppConstants'
import {Typography, Colors, Spacing} from '../../assets/styles'

const useStyles = makeStyles({
  warningContainer: {
    marginBottom: Spacing.spaceMedium,
    padding: Spacing.spaceSmall,
    backgroundColor: Colors.rxrLightGreyColor,
    display: 'flex',
    borderRadius: 5,
  },
  warningIconContainer: {
    marginRight: Spacing.spaceSmall,
    marginTop: Spacing.spaceExtraExtraSmall,
  },
  warningTitle: {
    color: Colors.rxrBlackColor,
    fontSize: Typography.fontSizeLarge,
    fontWeight: Typography.mediumFontWeight,
  },
  warningContent: {
    color: Colors.rxrBlackColor,
    fontSize: Typography.fontSizeMedium,
    fontWeight: Typography.lightFontWeight,
  },
})

const DelinquentWarningCard = ({residentId, isViewAppointment}) => {
  const classes = useStyles()
  const [showCard, setShowCard] = useState(false)
  const {getResident} = useResidentLookup()

  useEffect(() => {
    if (residentId) {
      const resident = getResident(residentId)
      if (
        resident &&
        resident.standing &&
        (resident.standing === RESIDENT_STANDING_DELINQUENT || resident.standing === RESIDENT_STANDING_WARNING)
      ) {
        setShowCard(true)
      }
    }
  }, [residentId])

  return (
    <div>
      {showCard && (
        <div className={classes.warningContainer}>
          <div className={classes.warningIconContainer}>
            <RXRIcon icon={RXRIcon.INFO} color={Colors.rxrRedColor} />
          </div>
          {isViewAppointment ? (
            <div>
              <div className={classes.warningTitle}>Cancelled due to delayed payments on previous bookings.</div>
              <div className={classes.warningContent}>
                No future reservations can be made until payments for previous bookings have been cleared. Staff may schedule new bookings
                on resident’s behalf if needed.
              </div>
            </div>
          ) : (
            <div className={classes.warningTitle}>
              <div>
                Alert- two or more unpaid bookings. No future reservations can be made on the resident app until previous bookings have been
                paid.
              </div>
              <div>Booking on the dashboard possible at the staff users discretion.</div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

DelinquentWarningCard.propTypes = {
  residentId: PropTypes.string,
  isViewAppointment: PropTypes.bool,
}

export default DelinquentWarningCard
