import gql from 'graphql-tag'

const S3ObjectFields = `
key
region
bucket
displayName
contentType
`

export const AnnouncementFragment = gql`
  fragment AnnouncementFragment on Announcement {
    id
    activeStart
    activeEnd
    headline
    text
    htmlText
    announcementsBuildingId
    staffId
    createdAt
    updatedAt
    audienceLabel
    attachments {
      ${S3ObjectFields}
    }
  }
`

export const PetProfileFragment = gql`
  fragment PetProfileFragment on PetProfile {
    id
    displayName
    birthday
    species
    breed
    weight
    image {
      ${S3ObjectFields}
    }
    notes
    isDeleted
  }
`

export const ResidentFragment = gql`
  fragment ResidentFragment on Resident {
    id
    createdAt
    updatedAt
    dateClaimed
    phoneNumber
    email
    displayName
    standing
    referenceId
    secondaryReferenceId
    userProfile
    lastLoggedInAt
    onboardingMeta
    profileImage {
      ${S3ObjectFields}
    }
    moveInDate
    statusOverride
    isDeleted
    isTest
    conversation {
      id
    }
    autoPay {
      items {
        id
        active
      }
    }
  }
`
export const UnitFragment = gql`
  fragment UnitFragment on Unit {
    id
    number
    sqft
    bedrooms
    bathrooms
    unitBuildingId
  }
`

export const IncomingDeliveryFragment = gql`
  fragment IncomingDeliveryFragment on Delivery {
    id
    trackingNumber
    carrier
    packageType
    priority
    notes
    createdAt
    receivedAt
    isDeleted
    residentId
    unitId
    buildingId
    deliveredDetails {
      deliveredTo
      recipientName
      staffId
    }
    staffId
    updatedAt
  }
`

export const OutgoingDeliveryFragment = gql`
  fragment OutgoingDeliveryFragment on OutgoingDelivery {
    id
    carrier
    packageType
    residentId
    buildingId
    unitId
    shippedAt
    createdAt
    trackingNumber
    notes
    isDeleted
    staffId
  }
`

export const AmenityFragment = gql`
  fragment AmenityFragment on Amenity {
    id
    label
    needsApproval
    isVisible
    isOnboardingOnly
  }
`

export const AmenityClosureFragment = gql`
  fragment AmenityClosureFragment on ClosureResponse {
    id
    resourceId
    label
    startAt
    endAt
  }
`

export const AmenityGeneralAvailabilityFragment = gql`
  fragment AmenityGeneralAvailabilityFragment on GeneralAvailabilityResponse {
    id
    resourceId
    dayOfWeek
    startTime
    endTime
  }
`

export const GetAmenityFragment = gql`
  fragment GetAmenityFragment on GetAmenityResponse {
    id
    buildingId
    label
    description
    rules
    mustAgreeToRules
    needsApproval
    weeklyReservationLimit
    attachments {
      ${S3ObjectFields}
    }
    isVisible
    resourceId
    isOnboardingOnly
    advancedNoticeCancel
    advancedNoticeRequest
    minReservationDuration
    maxReservationDuration
    maxPartySize
    maxOccupancy
    maxConcurrentReservations
    reservationPadding
    reservationIncrement
    ignoreBookInAdvanceForStaff
  }
`
export const AmenityDetailsFragment = gql`
  ${AmenityClosureFragment}
  ${AmenityGeneralAvailabilityFragment}
  fragment AmenityDetailsFragment on GetAmenityDetailsResponse {
    id
    buildingId
    label
    description
    rules
    mustAgreeToRules
    ignoreBookInAdvanceForStaff
    needsApproval
    weeklyReservationLimit
    attachments {
      ${S3ObjectFields}
    }
    isVisible
    isOnboardingOnly
    advancedNoticeCancel
    advancedNoticeRequest
    minReservationDuration
    maxReservationDuration
    maxPartySize
    maxOccupancy
    maxConcurrentReservations
    reservationPadding
    reservationIncrement
    closures {
      ...AmenityClosureFragment
    }
    generalAvailability {
      ...AmenityGeneralAvailabilityFragment
    }
  }
`

export const AmenityReservationFragment = gql`
  fragment AmenityReservationFragment on AmenityReservation {
    id
    reservationId
    amenityId
    buildingId
    residentId
    staffId
    startAt
    endAt
    partySize
    reservationState
    isSystemControlled
    useCase
    notes
    title
    createdAt
    updatedAt
    isOnboardingOnly
  }
`

export const StaffNoteFragment = gql`
  fragment StaffNoteFragment on StaffNote {
    id
    buildingId
    staffId
    staff {
      displayName
    }
    content
    isDeleted
    isSticky
    createdAt
    updatedAt
    staffNoteId
  }
`

export const MessageFragment = gql`
  fragment MessageFragment on Message {
    id
    type
    topic
    body
    messageStaffId
    messageConversationId
    staff {
      id
      displayName
    }
    image {
      ${S3ObjectFields}
    }
    recipient
    createdAt
  }
`

export const ConversationFragment = gql`
  ${MessageFragment}
  fragment ConversationFragment on Conversation {
    id
    conversationBuildingId
    conversationResidentId
    staffLastReadAt
    residentReadAt
    lastMessageAt
    status
    lastMessage {
      ...MessageFragment
    }
    resident {
      id
      displayName
    }
  }
`

export const ChatParticipantFragment = gql`
  fragment ChatParticipantFragment on ChatParticipant {
    id
    chatRoomId
    participantId
    displayName
    isStaffParticipant
    lastSeenAt
    createdAt
  }
`

export const ChatMessageFragment = gql`
  fragment ChatMessageFragment on ChatMessage {
    id
    chatRoomId
    chatParticipantId
    content
    isAttachments
    createdAt
  }
`

export const ChatRoomFragment = gql`
  ${ChatMessageFragment}
  ${ChatParticipantFragment}
  fragment ChatRoomFragment on ChatRoom {
    id
    displayName
    status
    lastChatMessageId
    lastChatMessage {
      ...ChatMessageFragment
    }
    chatParticipants {
      items {
        ...ChatParticipantFragment
      }
    }
  }
`

export const GuestFragment = gql`
  fragment GuestFragment on Guest {
    id
    firstName
    lastName
    registrationExpiry
    meetingOption
    instructions
    residentId
    buildingId
    unitId
    isDeleted
    createdAt
    updatedAt
  }
`

export const VendorServiceFragment = gql`
  fragment VendorServiceFragment on VendorService {
    id
    foreignServiceId
    buildingId
    label
    type
    subType
    price
    createdAt
    updatedAt
    addOnOptions {
      label
      price
      description
    }
  }
`

export const PaymentMethodFragment = gql`
  fragment PaymentMethodFragment on PaymentMethod {
    id
    deleted
    nickName
    type
  }
`

const PaymentFragment = gql`
  fragment PaymentFragment on Payment {
    id
    residentId
    paymentReferenceId
    paymentTraceId
    payeeId
    transactionId
    paymentMethodId
    invoiceId
    incurFee
    feeAmount
    totalAmount
    unitAmount
    status
    timestamp
    updatedAt
    failureReason
    paymentMethod {
      id
      nickName
      type
    }
  }
`

export const VendorServiceCreditFragment = gql`
  fragment VendorServiceCreditFragment on VendorServiceCredit {
    id
    category
    title
    description
    amount
    discountType
    expiredAt
    vendorServiceTypes
    notes
    staffId
    vendorAppointmentId
    vendorServicePromotionId
  }
`

export const VendorServicePromotionFragment = gql`
  fragment VendorServicePromotionFragment on VendorServicePromotion {
    id
    buildingId
    title
    description
    amount
    discountType
    startedAt
    expiredAt
    vendorServiceTypes
  }
`

export const VendorAppointmentFragment = gql`
  ${VendorServiceFragment}
  ${PaymentFragment}
  ${PaymentMethodFragment}
  ${PetProfileFragment}
  ${VendorServiceCreditFragment}
  fragment VendorAppointmentFragment on VendorAppointment {
    id
    vendorServiceId
    vendorService {
      ...VendorServiceFragment
    }
    foreignAppointmentId
    buildingId
    unitId
    residentId
    status
    startAt
    endAt
    notes
    cost
    paymentId
    payment {
      ...PaymentFragment
    }
    paymentMethodId
    paymentMethod {
      ...PaymentMethodFragment
    }
    recurringVendorAppointmentId
    recurringVendorAppointment {
      id
      frequency
    }
    addOns {
      label
      cost
    }
    petProfiles {
      items {
        petProfile {
          ...PetProfileFragment
        }
      }
    }

    vendorServiceCredits {
      items {
        ...VendorServiceCreditFragment
      }
    }
    createdAt
    updatedAt
  }
`

export const BuildingNudgeFragment = gql`
  fragment BuildingNudgeFragment on BuildingNudge {
    id
    buildingId
    residentId
    type
    label
    triggeredAt
    expiredAt
    isDeleted
    createdAt
    updatedAt
  }
`

export const BuildingActionFragment = gql`
  fragment BuildingActionFragment on BuildingAction {
    id
    buildingId
    residentId
    staffId
    buildingNudgeId
    cost
    reason
    notes
    actions
    actionTakenAt
    createdAt
    updatedAt
  }
`

export const ServiceRequestFragment = gql`
  fragment ServiceRequestFragment on ServiceRequest {
    id
    serviceRequestResidentId
    category
    currentStatus
    requestedBy
    createdAt
    yardiServiceRequestId
    accessNotes
    attachments {
      ${S3ObjectFields}
    }
    briefDescription
    fullDescription
    technicianNotes
    statusHistory {
      status
      timestamp
      yardiStatus
    }
    hasPermissionToEnter
  }
`

export const InvoiceFragment = gql`
  ${PaymentFragment}
  fragment InvoiceFragment on Invoice {
    id
    buildingId
    occupancyId
    subTotal
    tax
    fee
    total
    amountPaid
    status
    statusHistory {
      timestamp
      status
    }
    payments {
      items {
        ...PaymentFragment
      }
    }
    issuedAt
    dueAt
    createdAt
  }
`

export const UnitTagFragment = gql`
  fragment UnitTagFragment on UnitTag {
    id
    label
    units(limit: 100) {
      items {
        unitId
      }
    }
  }
`

export const StaffFragment = gql`
  fragment StaffFragment on Staff {
    id
    userName
    displayName
    permissionGroup
    profileImage {
      ${S3ObjectFields}
    }
  }
`

export const BuildingStaffFragment = gql`
  ${StaffFragment}
  fragment BuildingStaffFragment on BuildingStaff {
    id
    buildingId
    staffId
    isDisabled
    staff {
      ...StaffFragment
    }
  }
`

export const OnboardingTaskFragment = gql`
  fragment OnboardingTaskFragment on OnboardingTask {
    id
    occupancyId
    state
    requiresApproval
    label
    key
    form
    taskMeta
    createdAt
    updatedAt
    stateHistory {
      state
      previousState
      modifiedBy
      timestamp
    }
    importance
    supportsMultipleSubmissions
  }
`

export const OccupancyFragment = gql`
  ${OnboardingTaskFragment}
  ${ResidentFragment}
  fragment OccupancyFragment on Occupancy {
    id
    createdAt
    status
    leaseFromDate
    leaseToDate
    mustUpdateLedger
    ledgerItems {
      chargeId
      chargeDate
      amount
      label
      description
    }
    occupancyUnitId
    yardiId
    onboardingMeta
    onboardingTasks(limit: 100) {
      items {
        ...OnboardingTaskFragment
      }
    }
    residents(limit: 100) {
      items {
        ...ResidentFragment
      }
    }
  }
`

export const PartnerFragment = gql`
  fragment PartnerFragment on Partner {
    id
    displayName
    description
    shortDescription
    callToAction
    coverImage {
      ${S3ObjectFields}
    }
    logo {
      ${S3ObjectFields}
    }
    landingUrl
    email
    phoneNumber
    order
  }
`

export const NotificationSettingFragment = gql`
  fragment NotificationSettingFragment on NotificationSetting {
    id
    category
    medium
    residentId
    isUnsubscribed
  }
`
