import QueryHelper from '../lib/QueryHelper'
import moment from 'moment'
import {
  AnnouncementFragment,
  AmenityClosureFragment,
  AmenityDetailsFragment,
  AmenityGeneralAvailabilityFragment,
  AmenityReservationFragment,
  BuildingNudgeFragment,
  BuildingActionFragment,
  ChatRoomFragment,
  ChatMessageFragment,
  ConversationFragment,
  GetAmenityFragment,
  GuestFragment,
  IncomingDeliveryFragment,
  MessageFragment,
  OutgoingDeliveryFragment,
  PaymentMethodFragment,
  ResidentFragment,
  StaffNoteFragment,
  UnitFragment,
  VendorAppointmentFragment,
  VendorServiceFragment,
  ServiceRequestFragment,
  PetProfileFragment,
  InvoiceFragment,
  VendorServiceCreditFragment,
  VendorServicePromotionFragment,
  UnitTagFragment,
  BuildingStaffFragment,
  OnboardingTaskFragment,
  AmenityFragment,
  PartnerFragment,
  OccupancyFragment,
  StaffFragment,
  NotificationSettingFragment,
} from './modelFragments'
import gql from 'graphql-tag'
import {CONVERSATION_ARCHIVED, INVOICE_STATUS_CANCELLED, INVOICE_STATUS_PAID} from '../constants/ModelConstants'
import VendorServiceDiscount from './VendorServiceDiscount'
import FileHelper from './FileHelper'
import {VENDOR_SERVICE_VENDOR_NO_SHOW} from '../components/Services/VendorServiceConstants'
import {v4 as uuid} from 'uuid'

const S3ObjectFields = `
key
region
bucket
displayName
contentType
`

/**
 * @param {string} buildingId
 * @returns {Promise<*>}
 */

export async function getAllVendorAppointments(buildingId) {
  return await QueryHelper.Instance().queryAll(
    gql`
      ${VendorAppointmentFragment}
      query ListVendorAppointmentByBuilding(
        $buildingId: ID
        $startAt: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelVendorAppointmentFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listVendorAppointmentsByBuilding(
          buildingId: $buildingId
          startAt: $startAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            ...VendorAppointmentFragment
          }
          nextToken
        }
      }
    `,
    {
      buildingId: buildingId,
      limit: 500, // we were getting Resolver Limit Reach errors when this value was too large
    },
  )
}

/**
 * @param {string} residentId
 * @param {string} buildingId
 * @param {boolean?} unClaimedOnly
 * @returns {Promise<Array<VendorServiceDiscount>>}
 */
export async function getDiscountsForResident(residentId, buildingId, unClaimedOnly = false) {
  if (unClaimedOnly) {
    throw new Error('unClaimedOnly not implemented yet')
  }

  const promotions = await QueryHelper.Instance().queryAll(
    gql`
      ${VendorServicePromotionFragment}
      query ListVendorServicePromotionsByBuilding($buildingId: ID!, $nextToken: String, $limit: Int) {
        listVendorServicePromotionsByBuilding(buildingId: $buildingId, nextToken: $nextToken, limit: $limit) {
          items {
            ...VendorServicePromotionFragment
          }
          nextToken
        }
      }
    `,
    {
      buildingId: buildingId,
    },
  )

  const promosLookup = promotions.reduce((agr, p) => {
    agr[p.id] = p
    return agr
  }, {})

  const credits = await QueryHelper.Instance().queryAll(
    gql`
      ${VendorServiceCreditFragment}
      query ListVendorServiceCreditsByResident($residentId: ID!, $nextToken: String, $limit: Int) {
        listVendorServiceCreditsByResident(residentId: $residentId, nextToken: $nextToken, limit: $limit) {
          items {
            ...VendorServiceCreditFragment
          }
          nextToken
        }
      }
    `,
    {
      residentId: residentId,
    },
  )

  // remove all promotions that have already been claimed
  credits.forEach(c => {
    if (c.vendorServicePromotionId) {
      delete promosLookup[c.vendorServicePromotionId]
    }
  })

  return Object.values(promosLookup)
    .map(p => new VendorServiceDiscount(p, VendorServiceDiscount.TYPE_PROMOTION))
    .concat(credits.map(c => new VendorServiceDiscount(c, VendorServiceDiscount.TYPE_CREDIT)))
}

/**
 * @param {*} input
 * @returns {Promise<VendorServiceDiscount>}
 */
export async function createNewVendorCreditForResident(input) {
  const r = await QueryHelper.Instance().executeMutation(
    gql`
      ${VendorServiceCreditFragment}
      mutation CreateVendorServiceCredit($input: CreateVendorServiceCreditInput!) {
        createVendorServiceCredit(input: $input) {
          ...VendorServiceCreditFragment
        }
      }
    `,
    {
      input: input,
    },
  )

  return new VendorServiceDiscount(r, VendorServiceDiscount.TYPE_CREDIT)
}

/**
 * @param {string} buildingId
 * @returns {Promise<*>}
 */

/**
 * @param {*} input
 * @returns {Promise<*>}
 */
export async function createNewAnnouncement(input) {
  // we need to set the id explicitly so we can check for this announcement in case creation times out
  input.id = uuid()

  return await QueryHelper.Instance().executeMutation(
    gql`
      ${AnnouncementFragment}
      mutation CreateAnnouncement($input: CreateAnnouncementInput!) {
        createAnnouncement(input: $input) {
          ...AnnouncementFragment
        }
      }
    `,
    {
      input: input,
    },
  )
}

/**
 * @param {*} input
 * @returns {Promise<*>}
 */
export async function createVendorServicePromotion(input) {
  return await QueryHelper.Instance().executeMutation(
    gql`
      ${VendorServicePromotionFragment}
      mutation CreateVendorServicePromotion($input: CreateVendorServicePromotionInput!) {
        createVendorServicePromotion(input: $input) {
          ...VendorServicePromotionFragment
        }
      }
    `,
    {
      input: input,
    },
  )
}

/**
 * @param {*} input
 * @returns {Promise<*>}
 */
export async function updateVendorServicePromotion(input) {
  return await QueryHelper.Instance().executeMutation(
    gql`
      ${VendorServicePromotionFragment}
      mutation UpdateVendorServicePromotion($input: UpdateVendorServicePromotionInput!) {
        updateVendorServicePromotion(input: $input) {
          ...VendorServicePromotionFragment
        }
      }
    `,
    {
      input: input,
    },
  )
}

/**
 * @param {string} buildingId
 * @return {Promise<Array<*>>}
 */
export async function listAnnouncements(buildingId) {
  const retVal = await QueryHelper.Instance().queryAll(
    gql`
      ${AnnouncementFragment}
      query AnnouncementsByBuildingAndActiveEnd($buildingId: ID, $limit: Int, $nextToken: String) {
        announcementsByBuildingAndActiveEnd(announcementsBuildingId: $buildingId, limit: $limit, nextToken: $nextToken) {
          items {
            ...AnnouncementFragment

            residents(limit: 1000) {
              items {
                residentId
              }
            }
          }
          nextToken
        }
      }
    `,
    {buildingId: buildingId},
  )

  // we just want the resident IDs
  retVal.forEach(a => {
    if (a.residents.items.length > 0) {
      a.residentIds = a.residents.items.map(r => r.residentId)
    }
    delete a.residents
  })

  return retVal
}

export async function updateAnnouncement(input) {
  return await QueryHelper.Instance().executeMutation(
    gql`
      ${AnnouncementFragment}
      mutation UpdateAnnouncement($input: UpdateAnnouncementInput!) {
        updateAnnouncement(input: $input) {
          ...AnnouncementFragment
        }
      }
    `,
    {
      input: input,
    },
  )
}

export async function deleteAnnouncement(announcementId) {
  await QueryHelper.Instance().queryOnce(
    gql`
      mutation DeleteAnnouncement($input: DeleteAnnouncementInput!) {
        deleteAnnouncement(input: $input) {
          id
        }
      }
    `,
    {
      input: {id: announcementId},
    },
  )
}

/**
 * @param {*} input
 * @returns {Promise<*>}
 */
export async function createSocialEvent(input) {
  return await QueryHelper.Instance().executeMutation(
    gql`
      mutation CreateSocialEvent($input: CreateSocialEventInput!) {
        createSocialEvent(input: $input) {
          id
          eventName
          startTime
          endTime
          location
          previewDescription
          description
          rsvpRequired
          capacity
          allowResidentsToViewRsvps
          allowResidentGuestRsvps
          allowWaitlist
        }
      }
    `,
    {
      input: input,
    },
  )
}

/**
 * @param {{
 *   displayName: string,
 *   userName: string,
 *   buildingId: Array<string>, -- max length 1
 *   groupName: string,
 *   password?: string,
 * }} input
 * @returns {Promise<StaffFragment>}
 */
export async function createGroupUser(input) {
  return await QueryHelper.Instance().executeMutation(
    gql`
      ${StaffFragment}
      mutation CreateGroupUser($input: CreateGroupUserInput!) {
        createGroupUser(input: $input) {
          ...StaffFragment
        }
      }
    `,
    {
      input: input,
    },
  )
}

/**
 *
 * @param {{
 *   staffId: string,
 *   displayName?: string,
 *   userName?: string,
 *   password?: string,
 *   groupName?: string,
 *   profileImage?: LocalS3Object
 * }} input
 * @return {Promise<StaffFragment>}
 */
export async function updateGroupUser(input) {
  return await QueryHelper.Instance().executeMutation(
    gql`
      ${StaffFragment}
      mutation UpdateGroupUser($input: UpdateGroupUserInput!) {
        updateGroupUser(input: $input) {
          ...StaffFragment
        }
      }
    `,
    {
      input: input,
    },
  )
}

/**
 *
 * @param {{
 *   staffId: string,
 *   buildingId: string,
 * }} input
 * @return {Promise<StaffFragment>}
 */
export async function disableGroupUser(input) {
  return await QueryHelper.Instance().executeMutation(
    gql`
      ${StaffFragment}
      mutation DisableGroupUser($input: DisableGroupUserInput!) {
        disableGroupUser(input: $input) {
          ...StaffFragment
        }
      }
    `,
    {
      input: input,
    },
  )
}

/**
 * @param {string} emailAddress
 * @param {string} displayName
 * @param {string} buildingId
 * @return {Promise<StaffFragment>}
 */
export async function resendStaffInvite(emailAddress, displayName, buildingId) {
  // in the future, we should use a different backend hook, but for now the create hook will send an invite email if the user already exists with access at the property
  return await createGroupUser({
    userName: emailAddress,
    displayName: displayName,
    buildingId: [buildingId],
  })
}

export async function updateSocialEvent(input) {
  return await QueryHelper.Instance().executeMutation(
    gql`
      mutation UpdateSocialEvent($input: UpdateSocialEventInput!) {
        updateSocialEvent(input: $input) {
          id
          eventName
          startTime
          endTime
          location
          previewDescription
          description
        }
      }
    `,
    {
      input: input,
    },
  )
}

/**
 * @param {string} buildingId
 * @returns {Promise<Array<*>>}
 */
export async function listSocialEvents(buildingId) {
  return await QueryHelper.Instance().queryAll(
    gql`
      query ListSocialEventsByBuilding($buildingId: ID, $limit: Int, $nextToken: String) {
        listSocialEventsByBuilding(socialEventBuildingId: $buildingId, nextToken: $nextToken, limit: $limit) {
          items {
            id
            eventName
            location
            startTime
            endTime
            previewDescription
            description
            image {
              bucket
              region
              key
            }
            rsvpRequired
            capacity
            headcount
            allowResidentsToViewRsvps
            allowResidentGuestRsvps
            allowWaitlist
          }
          nextToken
        }
      }
    `,
    {buildingId: buildingId},
  )
}

/**
 * @param {string} eventId
 * @returns {Promise<void>}
 */
export async function deleteSocialEvent(eventId) {
  await QueryHelper.Instance().queryOnce(
    gql`
      mutation deleteSocialEvent($input: DeleteSocialEventInput!) {
        deleteSocialEvent(input: $input) {
          id
          eventName
          startTime
          endTime
          previewDescription
          description
          image {
            bucket
            region
            key
          }
        }
      }
    `,
    {
      input: {id: eventId},
    },
  )
}

/**
 * @param {string} id
 * @returns {Promise<*>}
 */
export async function getStaffModel(id) {
  const staff = await QueryHelper.Instance().queryOnce(
    gql`
      query GetStaff($id: ID!) {
        getStaff(id: $id) {
          id
          isAdmin
          phoneNumber
          displayName
          userName
          profileImage {
            ${S3ObjectFields}
          }
          permissionGroup
          dateClaimed
          lastLoggedInAt
          buildings {
            items {
              isDisabled
              building {
                id
                displayName
                yardiId
                outOfOfficeStatus
                disabledFeatures
                taxRate
                outOfOfficeMessage
                onboardingSchema
              }
            }
          }
        }
      }
    `,
    {
      id,
    },
  )
  const updatedBuildings = staff.buildings.items.filter(b => !b.isDisabled)
  staff.buildings.items = updatedBuildings
  return staff
}

/**
 * @param {string} staffId
 * @param {boolean} isFirstTime
 */
export async function markStaffUserLoggedIn(staffId, isFirstTime = false) {
  const now = new Date()
  const input = {
    id: staffId,
    lastLoggedInAt: now,
  }

  if (isFirstTime) {
    input.dateClaimed = now
  }

  try {
    await QueryHelper.Instance().executeMutation(
      gql`
        mutation UpdateStaff($input: UpdateStaffInput!) {
          updateStaff(input: $input) {
            id
          }
        }
      `,
      {
        input: input,
      },
    )
  } catch (err) {
    // we swallow this error because we don't _really_ care that much
    console.error('Failed to mark staff user logged in', err)
  }
}

/**
 * @param {string} id
 * @returns {Promise<*>}
 */
export async function getResident(id) {
  return await QueryHelper.Instance().queryOnce(
    gql`
      ${ResidentFragment}
      query GetResident($id: ID!) {
        getResident(id: $id) {
          ...ResidentFragment
        }
      }
    `,
    {
      id,
    },
  )
}

/**
 * @param {*} input
 * @returns {Promise<any>}
 */
export async function updateResidentProfile(input) {
  return await QueryHelper.Instance().queryOnce(
    gql`
      ${ResidentFragment}
      mutation updateResidentProfile($input: UpdateResidentInput!) {
        updateResident(input: $input) {
          ...ResidentFragment
        }
      }
    `,
    {
      input: input,
    },
  )
}

/**
 * @param {{
 *   messageConversationId: string,
 *   type: string,
 *   body: string,
 *   recipient: string,
 *   messageStaffId?: string,
 *   image?: {
 *     key: string,
 *     region: string,
 *     bucket: string
 *   }
 * }} input
 * @returns {Promise<MessageFragment>}
 */
export async function createMessage(input) {
  return await QueryHelper.Instance().executeMutation(
    gql`
      ${MessageFragment}
      mutation CreateMessage($input: CreateMessageInput!) {
        createMessage(input: $input) {
          ...MessageFragment
        }
      }
    `,
    {
      input: input,
    },
  )
}

/**
 * @param {string} messageId
 * @returns {Promise<MessageFragment>}
 */
export async function deleteMessage(messageId) {
  return await QueryHelper.Instance().executeMutation(
    gql`
      ${MessageFragment}
      mutation DeleteMessage($input: DeleteMessageInput!) {
        deleteMessage(input: $input) {
          ...MessageFragment
        }
      }
    `,
    {
      input: {
        id: messageId,
      },
    },
  )
}

/**
 * @param {*} input
 * @returns {Promise<*>}
 */
export async function updateResident(input) {
  return await QueryHelper.Instance().executeMutation(
    gql`
      mutation UpdateResident($input: UpdateResidentInput!) {
        updateResident(input: $input) {
          id
          phoneNumber
          displayName
          occupancy {
            id
            unit {
              id
              number
              unitBuildingId
            }
            residents {
              nextToken
            }
            cleaningRequests {
              nextToken
            }
          }
          conversation {
            id
            resident {
              id
              phoneNumber
              displayName
            }
            building {
              id
              displayName
            }
            lastMessageAt
            lastMessage {
              id
              createdAt
              recipient
              body
            }
            messages {
              nextToken
            }
          }
        }
      }
    `,
    {
      input: input,
    },
  )
}

/**
 * @param {*} input
 * @returns {Promise<*>}
 */
export const updateBuildingOOOStatus = async input => {
  return await QueryHelper.Instance().queryOnce(
    gql`
      mutation UpdateBuilding($input: UpdateBuildingInput!) {
        updateBuilding(input: $input) {
          id
          displayName
          yardiId
          outOfOfficeStatus
          outOfOfficeMessage
        }
      }
    `,
    {
      input: input,
    },
  )
}

/**
 * TODO: We need to add a BuildingID field to resident, this is insane
 * @param {string} buildingId
 * @returns {Promise<[Array<*>, Array<*>, Array<*>]>}
 */
export const loadUnitsAndResidentsAndOnboardingOccupanciesForBuilding = async buildingId => {
  let data = await QueryHelper.Instance().queryAll(
    gql`
      ${UnitFragment}
      ${OccupancyFragment}
      query ListUnits($unitBuildingId: ID, $nextToken: String, $limit: Int) {
        unitsByBuildingAndNumber(unitBuildingId: $unitBuildingId, nextToken: $nextToken, limit: $limit) {
          items {
            ...UnitFragment
            # we only want current, future, and notice occupancies
            occupancies(limit: 1000, filter: {or: [{status: {eq: "Current"}}, {status: {eq: "Notice"}}, {status: {eq: "Future"}}]}) {
              items {
                ...OccupancyFragment
              }
            }
          }
          nextToken
        }
      }
    `,
    {
      limit: 400,
      unitBuildingId: buildingId,
    },
  )

  let residents = []
  let units = []
  let occupancies = []
  data.forEach(u => {
    u.occupancies.items.forEach(o => {
      o.unit = u
      // pull the onboarding task items array up to the root level
      if (o.onboardingTasks) {
        o.onboardingTasks = o.onboardingTasks.items
      }
      occupancies.push(o)
      o.residents.items.forEach(r => {
        // hide all deleted and test users from dashboard
        if (!r.isDeleted && !r.isTest) {
          residents.push(r)
        }
        r.occupancy = o
      })
      delete o.residents
    })
    // remove our occupancies reference
    delete u.occupancies
    units.push(u)
  })

  return [units, residents, occupancies]
}

/**
 * @param {*} input
 * @returns {Promise<*>}
 */
export async function CreateNewDelivery(input) {
  return await QueryHelper.Instance().executeMutation(
    gql`
      mutation createDelivery($input: CreateDeliveryInput!) {
        createDelivery(input: $input) {
          id
          buildingId
          carrier
          notes
          packageType
          priority
          trackingNumber
        }
      }
    `,
    {
      input: input,
    },
  )
}

/**
 * @param {*} input
 * @returns {Promise<*>}
 */
export async function CreateNewOutgoingDelivery(input) {
  return await QueryHelper.Instance().executeMutation(
    gql`
      mutation createOutgoingDelivery($input: CreateOutgoingDeliveryInput!) {
        createOutgoingDelivery(input: $input) {
          id
          buildingId
          carrier
          packageType
          trackingNumber
          notes
        }
      }
    `,
    {
      input: input,
    },
  )
}

/**
 * @param {string} deliveryId
 * @returns {Promise<*>}
 */
export async function deleteIncomingDelivery(deliveryId) {
  return updateIncomingDelivery({
    id: deliveryId,
    isDeleted: true,
  })
}

/**
 * @param {string} deliveryId
 * @returns {Promise<*>}
 */
export async function unDeleteIncomingDelivery(deliveryId) {
  return updateIncomingDelivery({
    id: deliveryId,
    isDeleted: false,
  })
}

/**
 * @param {*} input
 * @returns {Promise<*>}
 */
export async function updateIncomingDelivery(input) {
  return await QueryHelper.Instance().executeMutation(
    gql`
      ${IncomingDeliveryFragment}
      mutation UpdateDelivery($input: UpdateDeliveryInput!) {
        updateDelivery(input: $input) {
          ...IncomingDeliveryFragment
        }
      }
    `,
    {
      input: input,
    },
  )
}

/**
 * @param {string} buildingId
 * @returns {Promise<Array<*>>}
 */
export async function getIncomingDeliveriesForBuilding(buildingId) {
  let results = await QueryHelper.Instance().queryAll(
    gql`
      ${IncomingDeliveryFragment}
      query ListDeliverysByBuilding(
        $buildingId: ID
        $createdAt: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelDeliveryFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listDeliverysByBuilding(
          buildingId: $buildingId
          createdAt: $createdAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            ...IncomingDeliveryFragment
          }
          nextToken
        }
      }
    `,
    {
      buildingId: buildingId,
    },
    undefined,
    40, // yikes.
  )

  // only return deliveries with a valid resident
  return results.filter(d => !!d.residentId)
}

/**
 * @param {string} id
 * @returns {Promise<*>}
 */
export async function getIncomingDelivery(id) {
  return await QueryHelper.Instance().queryOnce(
    gql`
      ${IncomingDeliveryFragment}
      query GetDelivery($id: ID!) {
        getDelivery(id: $id) {
          ...IncomingDeliveryFragment
        }
      }
    `,
    {
      id: id,
    },
  )
}

/**
 * @param {string} outgoingDeliveryId
 * @returns {Promise<*>}
 */
export async function deleteOutgoingDelivery(outgoingDeliveryId) {
  return updateOutgoingDelivery({
    id: outgoingDeliveryId,
    isDeleted: true,
  })
}

/**
 * @param {string} outgoingDeliveryId
 * @returns {Promise<*>}
 */
export async function unDeleteOutgoingDelivery(outgoingDeliveryId) {
  return updateOutgoingDelivery({
    id: outgoingDeliveryId,
    isDeleted: false,
  })
}

/**
 * @param {*} input
 * @returns {Promise<*>}
 */
export async function updateOutgoingDelivery(input) {
  return await QueryHelper.Instance().executeMutation(
    gql`
      ${OutgoingDeliveryFragment}
      mutation UpdateOutgoingDelivery($input: UpdateOutgoingDeliveryInput!) {
        updateOutgoingDelivery(input: $input) {
          ...OutgoingDeliveryFragment
        }
      }
    `,
    {
      input: input,
    },
  )
}

/**
 * @param {string} buildingId
 * @param {boolean?} isShipped
 * @returns {Promise<Array<*>>}
 */
export async function getOutgoingDeliveriesForBuilding(buildingId, isShipped) {
  let filterObj = {
    buildingId: buildingId,
    filter: {shippedAt: {notContains: '20'}}, // need to give by default
  }

  if (typeof isShipped === 'boolean') {
    // they'll start with 202X, so if they don't have it, then it's not delivered. This way we can support possible empty/null receivedAt values
    if (isShipped) {
      filterObj.filter.shippedAt = {contains: '20'}
    } else {
      filterObj.filter.shippedAt = {notContains: '20'}
    }
  } else {
    filterObj = {
      buildingId: buildingId,
    }
  }

  return await QueryHelper.Instance().queryAll(
    gql`
      ${OutgoingDeliveryFragment}
      query ListOutgoingDeliverysByBuilding(
        $buildingId: ID
        $createdAt: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelOutgoingDeliveryFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listOutgoingDeliverysByBuilding(
          buildingId: $buildingId
          createdAt: $createdAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            ...OutgoingDeliveryFragment
          }
          nextToken
        }
      }
    `,
    filterObj,
  )
}

/**
 * @param {string} id
 * @returns {Promise<*>}
 */
export async function getOutgoingDelivery(id) {
  return await QueryHelper.Instance().queryOnce(
    gql`
      ${OutgoingDeliveryFragment}
      query GetOutgoingDelivery($id: ID!) {
        getOutgoingDelivery(id: $id) {
          ...OutgoingDeliveryFragment
        }
      }
    `,
    {
      id: id,
    },
  )
}

/**
 * @param {string} buildingId
 * @returns {Promise<Array<*>>}
 */
export async function fetchGuestVisits(buildingId) {
  return await QueryHelper.Instance().queryAll(
    gql`
      query ListGuestVisitsByBuilding(
        $buildingId: ID
        $createdAt: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelGuestVisitFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listGuestVisitsByBuilding(
          buildingId: $buildingId
          createdAt: $createdAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            id
            createdAt
            isPermanent
            guestName
            residentName
            unitNumber
            guestId
            buildingId
          }
          nextToken
        }
      }
    `,
    {
      buildingId: buildingId,
    },
  )
}

/**
 * @param {string} id
 * @returns {Promise<*>}
 */
export async function getGuestInfo(id) {
  return await QueryHelper.Instance().queryOnce(
    gql`
      ${GuestFragment}
      query GetGuest($id: ID!) {
        getGuest(id: $id) {
          ...GuestFragment
        }
      }
    `,
    {
      id: id,
    },
  )
}

export async function getServiceRequest(id) {
  return await QueryHelper.Instance().queryOnce(
    gql`
      ${ServiceRequestFragment}
      query GetServiceRequest($id: ID!) {
        getServiceRequest(id: $id) {
          ...ServiceRequestFragment
        }
      }
    `,
    {
      id,
    },
  )
}

export async function getListResidentEventsByPrimaryRecordId(id, residentId) {
  let input = {
    id: id,
  }
  if (!!residentId) {
    input = {
      ...input,
      filter: {
        residentId: {eq: residentId},
      },
    }
  }
  return await QueryHelper.Instance().queryAll(
    gql`
      query ListResidentEventsByPrimaryRecordId($id: String, $limit: Int, $nextToken: String, $filter: ModelResidentEventFilterInput) {
        listResidentEventsByPrimaryRecordId(primaryRecordId: $id, limit: $limit, nextToken: $nextToken, filter: $filter) {
          items {
            id
            label
            shortLabel
            primaryRecord
            primaryRecordId
            residentId
            updatedAt
            createdAt
          }
          nextToken
        }
      }
    `,
    input,
  )
}

/**
 * @param {string} buildingId
 * @returns {Promise<Array<*>>}
 */
export async function fetchAllGuestPasses(buildingId) {
  if (!buildingId) {
    return
  }
  return await QueryHelper.Instance().queryAll(
    gql`
      ${GuestFragment}
      query ListGuestsByBuilding(
        $buildingId: ID
        $sortDirection: ModelSortDirection
        $filter: ModelGuestFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listGuestsByBuilding(
          buildingId: $buildingId
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            ...GuestFragment
          }
          nextToken
        }
      }
    `,
    {
      buildingId: buildingId,
      filter: {
        isDeleted: {ne: true},
      },
    },
  )
}

/**
 * @param {*} input
 * @returns {Promise<*>}
 */
export async function CreateGuestVisit(input) {
  return await QueryHelper.Instance().executeMutation(
    gql`
      mutation CreateGuestVisit($input: CreateGuestVisitInput!) {
        createGuestVisit(input: $input) {
          id
          createdAt
          isPermanent
          guestName
          residentName
          unitNumber
          guestId
          guest {
            id
            buildingId
            residentId
            unitId
          }
        }
      }
    `,
    {
      input: input,
    },
  )
}

/**
 * @param {{firstName: string,
      lastName: string,
      registrationExpiry: string,
      meetingOption: string,
      instructions: string,
      residentId: string,
      unitId: string,
      buildingId: string}} input
 * @returns {Promise<*>}
 */
export async function createNewGuest(input) {
  return await QueryHelper.Instance().executeMutation(
    gql`
      ${GuestFragment}
      mutation createGuest($input: CreateGuestInput!) {
        createGuest(input: $input) {
          ...GuestFragment
        }
      }
    `,
    {
      input: input,
    },
  )
}

/**
 * @param {{firstName: string,
      lastName: string,
      registrationExpiry: string,
      meetingOption: string,
      instructions: string,
      residentId: string,
      unitId: string,
      buildingId: string}} input
 * @returns {Promise<*>}
 */
export async function updateGuest(input) {
  return await QueryHelper.Instance().executeMutation(
    gql`
      ${GuestFragment}
      mutation UpdateGuest($input: UpdateGuestInput!) {
        updateGuest(input: $input) {
          ...GuestFragment
        }
      }
    `,
    {
      input: input,
    },
  )
}

/**
 * @param {string} buildingId
 * @returns {Promise<Array<*>>}
 */
export async function getAllAmenitys(buildingId) {
  return await QueryHelper.Instance().queryAll(
    gql`
      ${AmenityFragment}
      query ListAmenitysByBuilding($buildingId: ID!, $nextToken: String, $limit: Int) {
        listAmenitysByBuilding(buildingId: $buildingId, nextToken: $nextToken, limit: $limit) {
          items {
            ...AmenityFragment
          }
          nextToken
        }
      }
    `,
    {
      buildingId: buildingId,
    },
  )
}

/**
 * @param {*} resp
 * @return {*}
 * @private
 */
function _processAmenityDetailsResponse(resp) {
  // we want to start off with our closures and availability sorted. But we're sorting here and not in the component
  // because we only want it sorted on load and not in realtime as they make changes
  if (Array.isArray(resp.closures)) {
    // we're also hiding closures that were in the past
    resp.closures = resp.closures.filter(c => moment(c.endAt) > moment()).sort((a, b) => a.startAt - b.startAt)
  }

  if (Array.isArray(resp.generalAvailability)) {
    resp.generalAvailability.sort((a, b) => {
      return a.dayOfWeek === b.dayOfWeek ? a.startTime - b.startTime : a.dayOfWeek - b.dayOfWeek
    })
  }

  return resp
}

/**
 * @param {string} amenityId
 * @returns {Promise<*>}
 */
export const getAmenityDetails = async amenityId => {
  let retVal = await QueryHelper.Instance().queryOnce(
    gql`
      ${AmenityDetailsFragment}
      query GetAmenityDetails($input: CommonRequestInput!) {
        getAmenityDetails(input: $input) {
          ...AmenityDetailsFragment
        }
      }
    `,
    {input: {id: amenityId}},
  )

  return _processAmenityDetailsResponse(retVal)
}

/**
 * @param {Array<string>} amenityIds
 * @returns {Promise<Array<*>>}
 */
export const getAmenityDetailsBatch = async amenityIds => {
  const retVal = await QueryHelper.Instance().queryOnce(
    gql`
      ${AmenityDetailsFragment}
      query GetAmenityDetailsBatch($input: CommonRequestBatchInput!) {
        getAmenityDetailsBatch(input: $input) {
          batch {
            ...AmenityDetailsFragment
          }
        }
      }
    `,
    {input: {ids: amenityIds}},
  )

  return retVal.batch.map(r => _processAmenityDetailsResponse(r))
}

/**
 * @param {*} input
 * @param {Array<{id?:string, label: string, startAt: Date, endAt: Date}>} closures
 * @param {Array<{id?: string, dayOfWeek: number, startTime: number, endTime: number}>} availability
 * @returns {Promise<*>}
 */
export async function createNewAmenity(input, closures, availability) {
  // make sure we only pass the required fields
  input.attachments = input.attachments.map(a => FileHelper.formatS3ObjectForInput(a))

  let retVal = await QueryHelper.Instance().executeMutation(
    gql`
      ${GetAmenityFragment}
      mutation CreateNewAmenity($input: CreateNewAmenityInput!) {
        createNewAmenity(input: $input) {
          ...GetAmenityFragment
        }
      }
    `,
    {
      input: input,
    },
  )

  if (Array.isArray(closures)) {
    await Promise.all(
      (retVal.closures = closures.map(c => {
        return createClosure({...c, resourceId: retVal.resourceId})
      })),
    )
  }

  if (Array.isArray(availability)) {
    retVal.availability = await Promise.all(
      availability.map(a => {
        return createGeneralAvailability({...a, resourceId: retVal.resourceId})
      }),
    )
  }

  return retVal
}

/**
 * This will only update the dynamo record -- no resource modification at all
 * @param {*} input
 * @return {Promise<void>}
 */
export async function updateOnlyAmenity(input) {
  if (!input.id) {
    throw new Error('Missing amenity id to update')
  }

  return await QueryHelper.Instance().executeMutation(
    gql`
      mutation UpdateAmenity($input: UpdateAmenityInput!) {
        updateAmenity(input: $input) {
          id
          buildingId
          label
          description
          rules
          mustAgreeToRules
          needsApproval
          attachments {
            bucket
            key
            region
            displayName
            contentType
          }
          isVisible
        }
      }
    `,
    {
      input: input,
    },
  )
}

/**
 * @param {*} input
 * @param {Array<{id?:string, label: string, startAt: Date, endAt: Date}>?} closures
 * @param {Array<{id?: string, dayOfWeek: number, startTime: number, endTime: number}>?} availability
 * @returns {Promise<*>}
 */
export async function updateAmenity(input, closures, availability) {
  if (!input.id) {
    throw new Error('Missing amenity id to update')
  }

  if (Array.isArray(input.attachments)) {
    // make sure we only pass the required fields
    input.attachments = input.attachments.map(a => FileHelper.formatS3ObjectForInput(a))
  }

  let retVal = await QueryHelper.Instance().executeMutation(
    gql`
      ${GetAmenityFragment}
      mutation UpdateExistingAmenity($input: UpdateExistingAmenityInput!) {
        updateExistingAmenity(input: $input) {
          ...GetAmenityFragment
        }
      }
    `,
    {
      input: input,
    },
  )

  if (Array.isArray(closures)) {
    await Promise.all(
      (retVal.closures = closures.map(c => {
        if (c.id) {
          return updateClosure(c)
        } else {
          return createClosure({...c, resourceId: retVal.resourceId})
        }
      })),
    )
  }

  if (Array.isArray(availability)) {
    retVal.availability = await Promise.all(
      availability.map(a => {
        if (a.id) {
          return updateGeneralAvailability(a)
        } else {
          return createGeneralAvailability({...a, resourceId: retVal.resourceId})
        }
      }),
    )
  }

  return retVal
}

/**
 * @param {{id: string, label: string, startAt: Date, endAt: Date}} input
 * @returns {Promise<{id: string, amenityId: string, label: string, startAt: Date, endAt: Date}>}
 */
export async function updateClosure(input) {
  return await QueryHelper.Instance().executeMutation(
    gql`
      ${AmenityClosureFragment}
      mutation UpdateClosure($input: UpdateClosureInput!) {
        updateClosure(input: $input) {
          ...AmenityClosureFragment
        }
      }
    `,
    {
      input: {
        id: input.id,
        label: input.label,
        startAt: input.startAt,
        endAt: input.endAt,
      },
    },
  )
}

/**
 * @param {{id:string, dayOfWeek: number, startTime: number, endTime: number}} input
 * @returns {Promise<{id: string, amenityId: string, dayOfWeek: number, startTime: number, endTime: number}>}
 */
export async function updateGeneralAvailability(input) {
  return await QueryHelper.Instance().executeMutation(
    gql`
      ${AmenityGeneralAvailabilityFragment}
      mutation CreateGeneralAvailability($input: UpdateGeneralAvailabilityInput!) {
        updateGeneralAvailability(input: $input) {
          ...AmenityGeneralAvailabilityFragment
        }
      }
    `,
    {
      input: {
        id: input.id,
        dayOfWeek: input.dayOfWeek,
        startTime: input.startTime,
        endTime: input.endTime,
      },
    },
  )
}

/**
 * @param {{amenityId: string, label: string, startAt: Date, endAt: Date}} input
 * @returns {Promise<{id: string, amenityId: string, label: string, startAt: Date, endAt: Date}>}
 */
export async function createClosure(input) {
  return await QueryHelper.Instance().executeMutation(
    gql`
      ${AmenityClosureFragment}
      mutation CreateClosure($input: CreateClosureInput!) {
        createClosure(input: $input) {
          ...AmenityClosureFragment
        }
      }
    `,
    {
      input: {
        resourceId: input.resourceId,
        label: input.label,
        startAt: input.startAt,
        endAt: input.endAt,
      },
    },
  )
}

/**
 * @param {{amenityId: string, dayOfWeek: number, startTime: number, endTime: number}} input
 * @returns {Promise<{id: string, amenityId: string, dayOfWeek: number, startTime: number, endTime: number}>}
 */
export async function createGeneralAvailability(input) {
  return await QueryHelper.Instance().executeMutation(
    gql`
      ${AmenityGeneralAvailabilityFragment}
      mutation CreateGeneralAvailability($input: CreateGeneralAvailabilityInput!) {
        createGeneralAvailability(input: $input) {
          ...AmenityGeneralAvailabilityFragment
        }
      }
    `,
    {
      input: {
        resourceId: input.resourceId,
        dayOfWeek: input.dayOfWeek,
        startTime: input.startTime,
        endTime: input.endTime,
      },
    },
  )
}

/**
 * @param {string} closureId
 * @returns {Promise<void>}
 */
export async function deleteClosure(closureId) {
  return await QueryHelper.Instance().executeMutation(
    gql`
      mutation DeleteClosure($input: CommonRequestInput!) {
        deleteClosure(input: $input) {
          id
        }
      }
    `,
    {
      input: {id: closureId},
    },
  )
}

/**
 * @param {string} generalAvailabilityId
 * @returns {Promise<void>}
 */
export async function deleteGeneralAvailability(generalAvailabilityId) {
  return await QueryHelper.Instance().executeMutation(
    gql`
      mutation DeleteGeneralAvailability($input: CommonRequestInput!) {
        deleteGeneralAvailability(input: $input) {
          id
        }
      }
    `,
    {
      input: {id: generalAvailabilityId},
    },
  )
}

/**
 * @param {string} buildingId
 * @return {Promise<*>}
 */
export async function listAmenityReservationsByBuilding(buildingId) {
  return await QueryHelper.Instance().queryAll(
    gql`
      ${AmenityReservationFragment}
      query ListAmenityReservationsByBuilding(
        $buildingId: ID
        $filter: ModelAmenityReservationFilterInput
        $nextToken: String
        $limit: Int
      ) {
        listAmenityReservationsByBuilding(buildingId: $buildingId, filter: $filter, nextToken: $nextToken, limit: $limit) {
          items {
            ...AmenityReservationFragment
          }
          nextToken
        }
      }
    `,
    {
      buildingId: buildingId,
    },
  )
}

/**
 * @param {string} id
 * @returns {Promise<*>}
 */
export const getAmenityReservationDetails = async id => {
  return await QueryHelper.Instance().queryOnce(
    gql`
      ${AmenityReservationFragment}
      query GetAmenityReservation($id: ID!) {
        getAmenityReservation(id: $id) {
          ...AmenityReservationFragment
        }
      }
    `,
    {
      id: id,
    },
  )
}

/**
 * @param {string} id
 * @return {Promise<*>}
 */
export async function approveReservation(id) {
  return await QueryHelper.Instance().executeMutation(
    gql`
      ${AmenityReservationFragment}
      mutation ApproveAmenityReservation($input: CommonRequestInput!) {
        approveAmenityReservation(input: $input) {
          ...AmenityReservationFragment
        }
      }
    `,
    {
      input: {
        id: id,
      },
    },
  )
}

/**
 * @param {string} id
 * @return {Promise<*>}
 */
export async function declineReservation(id) {
  return await QueryHelper.Instance().executeMutation(
    gql`
      ${AmenityReservationFragment}
      mutation DeclineAmenityReservation($input: CommonRequestInput!) {
        declineAmenityReservation(input: $input) {
          ...AmenityReservationFragment
        }
      }
    `,
    {
      input: {
        id: id,
      },
    },
  )
}

/**
 * @param {string} id
 * @return {Promise<*>}
 */
export async function cancelReservation(id) {
  return await QueryHelper.Instance().executeMutation(
    gql`
      ${AmenityReservationFragment}
      mutation CancelAmenityReservation($input: CommonRequestInput!) {
        cancelAmenityReservation(input: $input) {
          ...AmenityReservationFragment
        }
      }
    `,
    {
      input: {
        id: id,
      },
    },
  )
}

/**
 *
 * @param {string} amenityId
 * @param {string?} residentId
 * @param {number} partySize
 * @param {Date} start
 * @param {Date} end
 * @param {string?} editingAmenityReservationId
 * @return {Promise<*>}
 */
export async function getAmenityCalendar(amenityId, residentId, partySize, start, end, editingAmenityReservationId) {
  return await QueryHelper.Instance().queryOnce(
    gql`
      query GetAmenityCalendar($input: GetAmenityCalendarInput!) {
        getAmenityCalendar(input: $input) {
          id
          reservationIncrement
          minReservationDuration
          maxReservationDuration
          availability {
            start
            end
          }
          generalAvailability {
            startTime
            endTime
            dayOfWeek
          }
        }
      }
    `,
    {
      input: {
        id: amenityId,
        residentId: residentId,
        partySize: partySize,
        start: start,
        end: end,
        editingAmenityReservationId: editingAmenityReservationId,
      },
    },
  )
}

/**
 * @param {*} input
 * @return {Promise<*>}
 */
export async function updateExistingAmenityReservation(input) {
  return await QueryHelper.Instance().executeMutation(
    gql`
      ${AmenityReservationFragment}
      mutation UpdateExistingAmenityReservation($input: UpdateExistingAmenityReservationInput!) {
        updateExistingAmenityReservation(input: $input) {
          ...AmenityReservationFragment
        }
      }
    `,
    {
      input,
    },
  )
}

/**
 * @param {*} input
 * @return {Promise<*>}
 */
export async function createNewAmenityReservation(input) {
  return await QueryHelper.Instance().executeMutation(
    gql`
      ${AmenityReservationFragment}
      mutation createNewAmenityReservation($input: CreateNewAmenityReservationInput!) {
        createNewAmenityReservation(input: $input) {
          ...AmenityReservationFragment
        }
      }
    `,
    {
      input: input,
    },
  )
}

export function formatStaffNote(note) {
  note.isPinned = note.isSticky === 'TRUE'
  delete note.isSticky
  return note
}

/**
 * @param {string} buildingId
 * @returns {QueryIterator}
 */
export function iterateStaffNotes(buildingId) {
  return QueryHelper.Instance().iterativeQuery(
    gql`
      ${StaffNoteFragment}
      query ListStaffNotesByBuilding($buildingId: ID!, $filter: ModelStaffNoteFilterInput, $nextToken: String, $limit: Int) {
        listStaffNotesByBuildingSticky(
          buildingId: $buildingId
          sortDirection: DESC # This puts "isSticky: TRUE" items to the top and also sorts by createdAt more recent -> less recent
          nextToken: $nextToken
          limit: $limit
          filter: $filter
        ) {
          items {
            ...StaffNoteFragment
          }
          nextToken
        }
      }
    `,
    {
      buildingId: buildingId,
      filter: {
        isDeleted: {ne: true},
      },
    },
  )
}

/**
 * @param {string} staffNoteId
 * @return {Promise<*>}
 */
export async function getStaffNote(staffNoteId) {
  const retVal = await QueryHelper.Instance().queryOnce(
    gql`
      ${StaffNoteFragment}
      query GetStaffNote($id: ID!) {
        getStaffNote(id: $id) {
          ...StaffNoteFragment
        }
      }
    `,
    {
      id: staffNoteId,
    },
  )

  return formatStaffNote(retVal)
}

/**
 * @param {{id: string, content?: string, isPinned?: boolean, createdAt?: string}} input
 * @return {Promise<void>}
 */
export async function updateStaffNote(input) {
  const finalInput = {...input}
  if (Object.hasOwn(finalInput, 'isPinned')) {
    finalInput.isSticky = finalInput.isPinned ? 'TRUE' : 'FALSE'
    delete finalInput.isPinned
  }
  const retVal = await QueryHelper.Instance().executeMutation(
    gql`
      ${StaffNoteFragment}
      mutation UpdateStaffNote($input: UpdateStaffNoteInput!) {
        updateStaffNote(input: $input) {
          ...StaffNoteFragment
        }
      }
    `,
    {
      input: finalInput,
    },
  )
  return formatStaffNote(retVal)
}

/**
 * @param {string} buildingId
 * @param {string} staffId
 * @param {string} noteContent
 * @param {string} staffNoteId
 * @return {Promise<*>}
 */
export async function createStaffNoteForBuilding(buildingId, staffId, noteContent, staffNoteId) {
  const input = {
    buildingId,
    staffId,
    content: noteContent,
    isSticky: 'FALSE',
  }
  if (staffNoteId) {
    input.staffNoteId = staffNoteId
  }

  const retVal = await QueryHelper.Instance().executeMutation(
    gql`
      ${StaffNoteFragment}
      mutation CreateStaffNote($input: CreateStaffNoteInput!) {
        createStaffNote(input: $input) {
          ...StaffNoteFragment
        }
      }
    `,
    {
      input,
    },
  )
  return formatStaffNote(retVal)
}

/**
 * @param {string} noteId
 * @return {Promise<void>}
 */
export async function deleteStaffNote(noteId) {
  const retVal = await QueryHelper.Instance().executeMutation(
    gql`
      ${StaffNoteFragment}
      mutation DeleteStaffNote($input: UpdateStaffNoteInput!) {
        updateStaffNote(input: $input) {
          ...StaffNoteFragment
        }
      }
    `,
    {
      input: {
        id: noteId,
        isDeleted: true,
      },
    },
  )
  return formatStaffNote(retVal)
}

/**
 * @param {string} residentId
 * @returns {QueryIterator}
 */
export function iterateResidentEventsByResident(residentId) {
  return QueryHelper.Instance().iterativeQuery(
    gql`
      query ListResidentEventsByResident($residentId: ID, $sortDirection: ModelSortDirection, $limit: Int, $nextToken: String) {
        listResidentEventsByResident(residentId: $residentId, sortDirection: $sortDirection, limit: $limit, nextToken: $nextToken) {
          items {
            id
            residentId
            label
            primaryRecord
            primaryRecordId
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    `,
    {
      residentId: residentId,
      sortDirection: 'DESC',
      limit: 40,
    },
  )
}

/*
 * @param {string} buildingId
 * @return {Promise<Array<ConversationFragment>>}
 */
export async function loadConversationsByBuilding(buildingId) {
  return await QueryHelper.Instance().queryAll(
    gql`
      ${ConversationFragment}
      query ListConversationsByBuilding($buildingId: ID, $limit: Int, $nextToken: String) {
        listConversationsByBuilding(conversationBuildingId: $buildingId, limit: $limit, nextToken: $nextToken) {
          items {
            ...ConversationFragment
          }
          nextToken
        }
      }
    `,
    {
      buildingId: buildingId,
    },
  )
}

/*
 * @param {string} buildingId
 * @return {Promise<Array<ChatRoomFragment>>}
 */
export async function loadChatRoomsByBuilding(buildingId) {
  const retVal = await QueryHelper.Instance().queryAll(
    gql`
      ${ChatRoomFragment}
      query ListChatRoomsByBuilding($buildingId: ID!, $status: ModelStringKeyConditionInput, $limit: Int, $nextToken: String) {
        listChatRoomsByBuilding(buildingId: $buildingId, status: $status, limit: $limit, nextToken: $nextToken) {
          items {
            ...ChatRoomFragment
          }
          nextToken
        }
      }
    `,
    {
      buildingId: buildingId,
      // we can do gt (greater than) because "ARCHIVED" starts with A and none of the others do
      status: {gt: CONVERSATION_ARCHIVED},
    },
  )

  retVal.forEach(c => {
    if (c.chatParticipants && Array.isArray(c.chatParticipants.items)) {
      c.chatParticipants = c.chatParticipants.items
    }
  })

  return retVal
}

/**
 * @param {string} id
 * @returns {Promise<*>}
 */
export async function getInvoiceInfo(id) {
  return await QueryHelper.Instance().queryOnce(
    gql`
      query GetInvoice($id: ID!) {
        getInvoice(id: $id) {
          id
          subTotal
          total
          status
          amountPaid
          statusHistory {
            timestamp
            status
          }
          payments {
            items {
              id
              status
              residentId
              paymentMethodId
              paymentMethod {
                id
                type
              }
            }
            nextToken
          }
        }
      }
    `,
    {
      id: id,
    },
  )
}

/**
 * @param {string} vendorAppointmentId
 * @return {Promise<{id: string}>}
 */
export async function chargeResidentForVendorAppointment(vendorAppointmentId) {
  return await QueryHelper.Instance().executeMutation(
    gql`
      mutation ChargeForCompletedVendorAppointment($input: ChargeForCompletedVendorAppointmentInput!) {
        chargeForCompletedVendorAppointment(input: $input) {
          id
        }
      }
    `,
    {
      input: {
        vendorAppointmentId: vendorAppointmentId,
      },
    },
  )
}

export async function markVendorNoShowForVendorAppointment(vendorAppointmentId) {
  return await QueryHelper.Instance().executeMutation(
    gql`
      ${VendorAppointmentFragment}
      mutation UpdateVendorAppointment($input: UpdateVendorAppointmentInput!) {
        updateVendorAppointment(input: $input) {
          ...VendorAppointmentFragment
        }
      }
    `,
    {
      input: {
        id: vendorAppointmentId,
        status: VENDOR_SERVICE_VENDOR_NO_SHOW,
      },
    },
  )
}

/**
 * @param {string} buildingId
 * @return {Promise<*>}
 */
export async function listNudgesByBuilding(buildingId) {
  return await QueryHelper.Instance().queryAll(
    gql`
      ${BuildingNudgeFragment}
      ${BuildingActionFragment}
      query ListBuildingNudgesByBuilding(
        $buildingId: ID
        $createdAt: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelBuildingNudgeFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listBuildingNudgesByBuilding(
          buildingId: $buildingId
          createdAt: $createdAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            ...BuildingNudgeFragment
            buildingActions {
              items {
                ...BuildingActionFragment
              }
            }
          }
          nextToken
        }
      }
    `,
    {
      buildingId: buildingId,
      filter: {
        expiredAt: {gt: new Date()},
        isDeleted: {ne: true},
      },
    },
  )
}

/**
 * @param {string} buildingId
 * @return {Promise<*>}
 */
export async function listLoggedNudgesActionByBuilding(buildingId) {
  return await QueryHelper.Instance().queryAll(
    gql`
      ${BuildingActionFragment}
      query ListBuildingActionsByBuilding(
        $buildingId: ID
        $createdAt: ModelStringKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelBuildingActionFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listBuildingActionsByBuilding(
          buildingId: $buildingId
          createdAt: $createdAt
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            ...BuildingActionFragment
          }
          nextToken
        }
      }
    `,
    {
      buildingId,
    },
  )
}

/**
 * @param {{buildingId: string, staffId: string, residentId: string, cost: number,reason: string, notes:string,actions: Array, actionTakenAt: Date, buildingNudgeId?: string }} input
 * @returns {Promise<{id: string, amenityId: string, dayOfWeek: number, startTime: number, endTime: number}>}
 */
export async function createBuildingAction(input) {
  return await QueryHelper.Instance().executeMutation(
    gql`
      mutation CreateBuildingAction($input: CreateBuildingActionInput!) {
        createBuildingAction(input: $input) {
          id
        }
      }
    `,
    {
      input: {
        buildingId: input.buildingId,
        staffId: input.staffId,
        residentId: input.residentId,
        cost: input.cost,
        reason: input.reason,
        notes: input.notes,
        actions: input.actions,
        actionTakenAt: input.actionTakenAt,
        buildingNudgeId: input.buildingNudgeId,
      },
    },
  )
}

/**
 * @param {string} vendorAppointmentId
 * @returns {Promise<*>}
 */
export async function getVendorAppointmentInfo(vendorAppointmentId) {
  return await QueryHelper.Instance().queryOnce(
    gql`
      ${VendorAppointmentFragment}
      query GetVendorAppointment($id: ID!) {
        getVendorAppointment(id: $id) {
          ...VendorAppointmentFragment
        }
      }
    `,
    {
      id: vendorAppointmentId,
    },
  )
}

/**
 * @param {String} buildingNudgeId
 * @returns {Promise<*>}
 */
export async function deleteBuildingNudge(buildingNudgeId) {
  return await QueryHelper.Instance().executeMutation(
    gql`
      ${BuildingNudgeFragment}
      mutation UpdateBuildingNudge($input: UpdateBuildingNudgeInput!) {
        updateBuildingNudge(input: $input) {
          ...BuildingNudgeFragment
        }
      }
    `,
    {
      input: {id: buildingNudgeId, isDeleted: true},
    },
  )
}

/**
 * @param {string} buildingId
 * @return {Promise<Array<*>>}
 */
export async function getAllVendorServices(buildingId) {
  return await QueryHelper.Instance().queryAll(
    gql`
      ${VendorServiceFragment}
      query ListVendorServicesByBuilding($buildingId: ID!, $nextToken: String, $limit: Int) {
        listVendorServicesByBuilding(buildingId: $buildingId, nextToken: $nextToken, limit: $limit) {
          items {
            ...VendorServiceFragment
          }
          nextToken
        }
      }
    `,
    {
      buildingId: buildingId,
    },
  )
}

/**
 * @param {string} residentId
 * @param {string} vendorServiceId
 * @param {Date} startAt
 * @param {Date} endAt
 * @param {string}paymentMethodId
 * @param {string?} notes
 * @param {Array<{label: string, cost: number}>?} addOns
 * @param {Array<VendorServiceDiscount>?} discounts
 * @returns {*}
 */
export async function createNewVendorAppointment(residentId, vendorServiceId, startAt, endAt, paymentMethodId, notes, addOns, discounts) {
  return await QueryHelper.Instance().executeMutation(
    gql`
      ${VendorAppointmentFragment}
      mutation CreateNewVendorAppointment($input: CreateNewVendorAppointmentInput!) {
        createNewVendorAppointment(input: $input) {
          ...VendorAppointmentFragment
        }
      }
    `,
    {
      input: {
        vendorServiceId: vendorServiceId,
        residentId: residentId,
        startAt: startAt,
        endAt: endAt,
        notes: notes,
        addOns: addOns,
        discounts: discounts.map(d => VendorServiceDiscount.toAppSyncInput(d)),
        paymentMethodId: paymentMethodId,
      },
    },
  )
}

/**
 * @param {string} vendorAppointmentId
 * @param {Date} startAt
 * @param {Date} endAt
 * @param {string}paymentMethodId
 * @param {string?} notes
 * @param {Array<{label: string, cost: number}>?} addOns
 * @param {Array<VendorServiceDiscount>?} discounts
 * @return {Promise<*>}
 */
export async function rescheduleExistingVendorAppointment(vendorAppointmentId, startAt, endAt, paymentMethodId, notes, addOns, discounts) {
  return await QueryHelper.Instance().executeMutation(
    gql`
      ${VendorAppointmentFragment}
      mutation RescheduleExistingVendorAppointment($input: RescheduleVendorAppointmentInput!) {
        rescheduleExistingVendorAppointment(input: $input) {
          ...VendorAppointmentFragment
        }
      }
    `,
    {
      input: {
        vendorAppointmentId: vendorAppointmentId,
        startAt: startAt,
        endAt: endAt,
        notes: notes,
        addOns: addOns,
        discounts: discounts.map(d => VendorServiceDiscount.toAppSyncInput(d)),
        paymentMethodId: paymentMethodId,
      },
    },
  )
}

/**
 * @param {string} vendorAppointmentId
 * @return {Promise<*>}
 */
export async function cancelExistingVendorAppointment(vendorAppointmentId) {
  return await QueryHelper.Instance().executeMutation(
    gql`
      ${VendorAppointmentFragment}
      mutation CancelExistingVendorAppointment($input: CancelVendorAppointmentInput!) {
        cancelExistingVendorAppointment(input: $input) {
          ...VendorAppointmentFragment
        }
      }
    `,
    {
      input: {
        vendorAppointmentId: vendorAppointmentId,
      },
    },
  )
}

/**
 * @param {string} vendorServiceId
 * @param {string} residentId
 * @param {string} buildingId
 * @param {string} unitId
 * @param {string} frequency
 * @param {Date} firstOccurredDate
 * @param {string} notes
 * @param {string} paymentMethodId
 * @param {string?} initialAppointmentId
 * @param {Array<{label: string, cost: number}>?} addOns
 * @param {Array<VendorServiceDiscount>?} discounts
 * @param {Array<string>?} metaPetProfileIds
 * @return {Promise<void>}
 */
export async function createNewRecurringVendorAppointment(
  vendorServiceId,
  residentId,
  buildingId,
  unitId,
  frequency,
  firstOccurredDate,
  notes,
  paymentMethodId,
  initialAppointmentId,
  addOns,
  metaPetProfileIds,
) {
  let retVal = await QueryHelper.Instance().executeMutation(
    gql`
      mutation CreateRecurringVendorAppointment($input: CreateRecurringVendorAppointmentInput!) {
        createRecurringVendorAppointment(input: $input) {
          id
        }
      }
    `,
    {
      input: {
        vendorServiceId: vendorServiceId,
        buildingId: buildingId,
        unitId: unitId,
        residentId: residentId,
        paymentMethodId: paymentMethodId,
        frequency: frequency,
        notes: notes,
        firstOccurredAt: firstOccurredDate,
        lastOccurredAt: firstOccurredDate,
        addOns: addOns,
        metaPetProfileIds: metaPetProfileIds,
      },
    },
  )

  try {
    if (initialAppointmentId) {
      await QueryHelper.Instance().executeMutation(
        gql`
          mutation UpdateVendorAppointment($input: UpdateVendorAppointmentInput!) {
            updateVendorAppointment(input: $input) {
              id
            }
          }
        `,
        {
          input: {
            id: initialAppointmentId,
            recurringVendorAppointmentId: retVal.id,
          },
        },
      )
    }
  } catch (err) {
    // NOTE: we don't throw. Since the primary mutation went through ok it's still good
  }

  return retVal
}

/**
 * @param {*} input
 * @return {Promise<*>}
 */
export async function updateRecurringVendorAppointment(input) {
  return await QueryHelper.Instance().executeMutation(
    gql`
      mutation UpdateRecurringVendorAppointment($input: UpdateRecurringVendorAppointmentInput!) {
        updateRecurringVendorAppointment(input: $input) {
          id
        }
      }
    `,
    {
      input: input,
    },
  )
}

/**
 * @param {string} vendorServiceId
 * @param {Date} date
 * @return {Promise<{duration: number, availability: Array<{start: Date, end: Date}>}>}
 */
export async function getVendorServiceAvailability(vendorServiceId, date) {
  return await QueryHelper.Instance().queryOnce(
    gql`
      query GetVendorServiceAvailability($input: GetVendorServiceAvailabilityInput!) {
        getVendorServiceAvailability(input: $input) {
          duration
          availability {
            start
            end
          }
        }
      }
    `,
    {
      input: {
        vendorServiceId: vendorServiceId,
        startAt: moment(date).startOf('month').toISOString(),
        endAt: moment(date).endOf('month').toISOString(),
      },
    },
  )
}

/**
 * @param {string} residentId
 * @returns {Promise<Array<*>>}
 */
export async function loadPaymentMethodsForResident(residentId) {
  let retVal = await QueryHelper.Instance().queryAll(
    gql`
      ${PaymentMethodFragment}
      query ListPaymentMethodsByResident($residentId: String, $nextToken: String, $limit: Int) {
        listPaymentMethodsByResident(residentId: $residentId, nextToken: $nextToken, limit: $limit) {
          items {
            ...PaymentMethodFragment
          }
          nextToken
        }
      }
    `,
    {
      residentId: residentId,
    },
  )

  return retVal.filter(p => !p.deleted)
}

/**
 * @param {string} buildingId
 * @return {Promise<Array<ServiceRequestFragment>>}
 */
export async function listServiceRequests(buildingId) {
  return await QueryHelper.Instance().queryAll(
    gql`
      ${ServiceRequestFragment}
      query ListServiceRequestsByBuilding($buildingId: ID!, $nextToken: String, $limit: Int) {
        listServiceRequestsByBuilding(buildingId: $buildingId, nextToken: $nextToken, limit: $limit) {
          items {
            ...ServiceRequestFragment
          }
          nextToken
        }
      }
    `,
    {
      buildingId: buildingId,
    },
  )
}

/**
 *
 * @param {{
 *   residentId: string,
 *   category: string,
 *   description: string,
 *   accessNotes: string,
 *   hasPermissionToEnter: boolean,
 * }} input
 * @return {Promise<ServiceRequestFragment>}
 */
export async function createNewServiceRequest(input) {
  return await QueryHelper.Instance().executeMutation(
    gql`
      ${ServiceRequestFragment}
      mutation CreateNewServiceRequest($input: CreateNewServiceRequestInput!) {
        createNewServiceRequest(input: $input) {
          ...ServiceRequestFragment
        }
      }
    `,
    {
      input: input,
    },
  )
}

/**
 * @param {string} serviceRequestId
 * @return {Promise<ServiceRequestFragment>}
 */
export async function cancelExistingServiceRequest(serviceRequestId) {
  return await QueryHelper.Instance().executeMutation(
    gql`
      ${ServiceRequestFragment}
      mutation CancelExistingServiceRequest($input: CancelExistingServiceRequestInput!) {
        cancelExistingServiceRequest(input: $input) {
          ...ServiceRequestFragment
        }
      }
    `,
    {
      input: {
        serviceRequestId: serviceRequestId,
      },
    },
  )
}

/**
 * @param {string} petProfileId
 * @param {string} vendorAppointmentId
 * @return {Promise<{id: string}>}
 */
export async function createPetProfileVendorAppointment(petProfileId, vendorAppointmentId) {
  return await QueryHelper.Instance().executeMutation(
    gql`
      mutation CreatePetProfileVendorAppointment($input: CreatePetProfileVendorAppointmentInput!) {
        createPetProfileVendorAppointment(input: $input) {
          id
        }
      }
    `,
    {
      input: {petProfileId: petProfileId, vendorAppointmentId: vendorAppointmentId},
    },
  )
}

/**
 * @param {string} occupancyId
 * @return {Promise<Array<PetProfileFragment>>}
 */
export async function listPetProfiles(occupancyId) {
  return await QueryHelper.Instance().queryAll(
    gql`
      ${PetProfileFragment}
      query ListPetProfilesByOccupancy($occupancyId: ID, $filter: ModelPetProfileFilterInput, $nextToken: String, $limit: Int) {
        listPetProfilesByOccupancy(occupancyId: $occupancyId, filter: $filter, nextToken: $nextToken, limit: $limit) {
          items {
            ...PetProfileFragment
          }
          nextToken
        }
      }
    `,
    {
      occupancyId: occupancyId,
      filter: {
        isDeleted: {ne: true},
      },
    },
  )
}

/**
 * @param {*} data
 * @return {Promise<PetProfileFragment>}
 */
export async function createPetProfile(data) {
  return await QueryHelper.Instance().executeMutation(
    gql`
      ${PetProfileFragment}
      mutation CreatePetProfile($input: CreatePetProfileInput!) {
        createPetProfile(input: $input) {
          ...PetProfileFragment
        }
      }
    `,
    {input: data},
  )
}

/**
 * @param {*} data
 * @return {Promise<PetProfileFragment>}
 */
export async function updatePetProfile(data) {
  return await QueryHelper.Instance().executeMutation(
    gql`
      ${PetProfileFragment}
      mutation UpdatePetProfile($input: UpdatePetProfileInput!) {
        updatePetProfile(input: $input) {
          ...PetProfileFragment
        }
      }
    `,
    {input: data},
  )
}

/**
 * @param {string} petProfileId
 * @return {Promise<void>}
 */
export async function deletePetProfile(petProfileId) {
  return await QueryHelper.Instance().executeMutation(
    gql`
      ${PetProfileFragment}
      mutation DeletePetProfile($input: DeletePetProfileInput!) {
        deletePetProfile(input: $input) {
          ...PetProfileFragment
        }
      }
    `,
    {input: {id: petProfileId}},
  )
}

/**
 * @param {string} occupancyId
 * @return {Promise<Array<*>>}
 */
export async function getActiveInvoiceByOccupancy(occupancyId) {
  let activeInvoice
  let sortedInvoiceList
  try {
    sortedInvoiceList = await QueryHelper.Instance().queryAll(
      gql`
        ${InvoiceFragment}
        query ListInvoicesByOccupancy($occupancyId: ID, $filter: ModelInvoiceFilterInput, $limit: Int, $nextToken: String) {
          listInvoicesByOccupancy(occupancyId: $occupancyId, filter: $filter, limit: $limit, nextToken: $nextToken, sortDirection: DESC) {
            items {
              ...InvoiceFragment
            }
            nextToken
          }
        }
      `,
      {
        occupancyId: occupancyId,
        filter: {
          and: [{status: {ne: INVOICE_STATUS_CANCELLED}}, {status: {ne: INVOICE_STATUS_PAID}}],
        },
      },
    )
  } catch (err) {
    console.error(err)
  }
  if (sortedInvoiceList && sortedInvoiceList.length > 0) {
    activeInvoice = sortedInvoiceList[0]
  }

  return activeInvoice
}

/**
 * @param {string} invoiceId
 * @return {Promise<*>}
 */
export async function closeInvoice(invoiceId) {
  return await QueryHelper.Instance().executeMutation(
    gql`
      ${InvoiceFragment}
      mutation ForceCloseInvoice($input: ForceUpdateInvoiceStatusInput!) {
        forceCloseInvoice(input: $input) {
          ...InvoiceFragment
        }
      }
    `,
    {
      input: {
        invoiceId: invoiceId,
      },
    },
  )
}

/**
 * @param {string} buildingId
 * @returns {Promise<{id: string, label: string, unitIds: Array<string>}>}
 */
export async function listUnitTagsForBuilding(buildingId) {
  const unitTags = await QueryHelper.Instance().queryAll(
    gql`
      ${UnitTagFragment}
      query ListUnitTagsByBuilding($buildingId: ID, $nextToken: String, $limit: Int) {
        listUnitTagsByBuilding(buildingId: $buildingId, nextToken: $nextToken, limit: $limit) {
          items {
            ...UnitTagFragment
          }
          nextToken
        }
      }
    `,
    {
      buildingId: buildingId,
    },
  )

  return unitTags.map(t => ({
    id: t.id,
    label: t.label,
    unitIds: t.units.items.map(ut => ut.unitId),
  }))
}

/**
 * @param {string} buildingId
 * @returns {Promise<Array<BuildingStaffFragment>>}
 */
export async function listBuildingStaffForBuilding(buildingId) {
  const buildingStaff = await QueryHelper.Instance().queryAll(
    gql`
      ${BuildingStaffFragment}
      query ListBuildingStaffByBuilding($buildingId: ID, $nextToken: String, $limit: Int) {
        listBuildingStaffByBuilding(buildingId: $buildingId, nextToken: $nextToken, limit: $limit) {
          items {
            ...BuildingStaffFragment
          }
          nextToken
        }
      }
    `,
    {
      buildingId: buildingId,
    },
  )

  return buildingStaff
}

/**
 * @return {Promise<{latestDashboardVersion: string}>}
 */
export async function getAppContext() {
  return await QueryHelper.Instance().queryOnce(gql`
    query RequestContext {
      requestContext {
        latestDashboardVersion
      }
    }
  `)
}

/**
 * @param {string} occupancyId
 * @return {Promise<*>}
 */
export async function getOccupancy(occupancyId) {
  const retVal = await QueryHelper.Instance().queryOnce(
    gql`
      ${OccupancyFragment}
      query GetOccupancy($id: ID!) {
        getOccupancy(id: $id) {
          ...OccupancyFragment
        }
      }
    `,
    {
      id: occupancyId,
    },
  )

  // pull the onboarding task items array up to the root level
  if (retVal.onboardingTasks) {
    retVal.onboardingTasks = retVal.onboardingTasks.items
  }

  return retVal
}

// Update occupancy AppSync QueryHelper mutation
export async function updateOccupancy(input) {
  const retVal = await QueryHelper.Instance().executeMutation(
    gql`
      ${OccupancyFragment}
      mutation UpdateOccupancy($input: UpdateOccupancyInput!) {
        updateOccupancy(input: $input) {
          ...OccupancyFragment
        }
      }
    `,
    {
      input,
    },
  )

  // pull the onboarding task items array up to the root level
  if (retVal.onboardingTasks) {
    retVal.onboardingTasks = retVal.onboardingTasks.items
  }

  return retVal
}

// Update onboardingTask AppSync QueryHelper mutation
export async function updateOnboardingTask(input) {
  return await QueryHelper.Instance().executeMutation(
    gql`
      ${OnboardingTaskFragment}
      mutation UpdateOnboardingTask($input: UpdateOnboardingTaskInput!) {
        updateOnboardingTask(input: $input) {
          ...OnboardingTaskFragment
        }
      }
    `,
    {
      input,
    },
  )
}

/**
 * @param {{buildingId: string, staffId: string, residentId: string, rating: number, feedback?:string, type: string}} input
 * @return {Promise<*>}
 */
export async function createFeedback(input) {
  return await QueryHelper.Instance().executeMutation(
    gql`
      mutation CreateFeedback($input: CreateFeedbackInput!) {
        createFeedback(input: $input) {
          id
          residentId
          staffId
          buildingId
          rating
          feedback
          type
          foreignId
          createdAt
          updatedAt
        }
      }
    `,
    {
      input,
    },
  )
}

/**
 * @param {string} buildingId
 * @return {Promise<Array<*>>}
 */
export async function getVendorServicePromotionsByBuilding(buildingId) {
  return await QueryHelper.Instance().queryAll(
    gql`
      ${VendorServicePromotionFragment}
      query ListVendorServicePromotionsByBuilding($buildingId: ID, $nextToken: String, $limit: Int) {
        listVendorServicePromotionsByBuilding(buildingId: $buildingId, nextToken: $nextToken, limit: $limit) {
          items {
            ...VendorServicePromotionFragment
          }
          nextToken
        }
      }
    `,
    {
      buildingId: buildingId,
    },
  )
}

/**
 * @typedef {Object} PartnerRecord
 * @property {string} id
 * @property {string} displayName
 * @property {string} description
 * @property {string} shortDescription
 * @property {string} callToAction
 * @property {LocalS3Object?} coverImage
 * @property {LocalS3Object?} logo
 * @property {string} landingUrl
 * @property {string} buildingId
 * @property {boolean} isFeatured
 * @property {number?} order
 */

/**
 * @param {string} buildingId
 * @return {Promise<Array<PartnerRecord>>}
 */
export async function listPartnersByBuilding(buildingId) {
  const response = await QueryHelper.Instance().queryAll(
    gql`
      ${PartnerFragment}
      query ListBuildingPartnersByBuilding($buildingId: ID, $nextToken: String, $limit: Int) {
        listBuildingPartnersByBuilding(buildingId: $buildingId, nextToken: $nextToken, limit: $limit) {
          items {
            buildingId
            isFeatured
            partner {
              ...PartnerFragment
            }
          }
          nextToken
        }
      }
    `,
    {buildingId: buildingId},
  )

  const retVal = response.map(flattenBuildingPartnerPayload)

  // --------------------------
  // this logic mirrors how it works in the app. Eventually all partners will have an explicit "order" and we can simplify or remove this entirely
  retVal.sort((a, b) => {
    // If both elements have an order field, sort these values based on the order field
    if (typeof a.order === 'number' && typeof b.order === 'number') {
      return a.order - b.order
      // Else if only element 'a' has an order field, order 'a' first
    } else if (a.order) {
      return -1
      // Else if only element 'b' has an order field, order 'b' first
    } else if (b.order) {
      return 1
      // Else if both elements don't have an order field, keep original order of a and b
    } else {
      return 0
    }
  })
  retVal.forEach((p, i) => (p.order = i + 1))
  // --------------------------

  return retVal
}

/**
 * @param {{buildingId: string, isFeatured?:boolean, partner: PartnerRecord}} bP
 * @return {PartnerRecord}
 */
function flattenBuildingPartnerPayload(bP) {
  return {...bP.partner, isFeatured: !!bP.isFeatured, buildingId: bP.buildingId}
}

/**
 * @param {string} partnerId
 * @param {string} buildingId
 * @return {Promise<{id: string, isFeatured?: boolean}>}
 */
async function getBuildingPartnerForPartnerAndBuilding(partnerId, buildingId) {
  // first we update the buildingPartner if needed
  const previous = await QueryHelper.Instance().queryAll(
    gql`
      query ListBuildingPartnersByPartner($partnerId: ID, $buildingId: ModelIDKeyConditionInput) {
        listBuildingPartnersByPartner(partnerId: $partnerId, buildingId: $buildingId) {
          items {
            id
            isFeatured
          }
        }
      }
    `,
    {
      partnerId: partnerId,
      buildingId: {eq: buildingId},
    },
  )

  if (previous.length === 0) {
    throw new Error('Cannot find Partner')
  }

  if (previous.length > 1) {
    throw new Error('Cannot modify Partner shared at multiple properties')
  }

  return previous[0]
}

/**
 * @param {PartnerRecord} partnerData
 * @return {Promise<PartnerRecord>}
 */
export async function createPartner(partnerData) {
  const {isFeatured, buildingId, ...partnerInput} = partnerData

  const partnerResponse = await QueryHelper.Instance().executeMutation(
    gql`
      mutation CreatePartner($input: CreatePartnerInput!) {
        createPartner(input: $input) {
          id
        }
      }
    `,
    {
      input: partnerInput,
    },
  )

  const buildingPartnerResponse = await QueryHelper.Instance().executeMutation(
    gql`
      ${PartnerFragment}
      mutation CreateBuildingPartner($input: CreateBuildingPartnerInput!) {
        createBuildingPartner(input: $input) {
          buildingId
          isFeatured
          partner {
            ...PartnerFragment
          }
        }
      }
    `,
    {
      input: {
        buildingId: buildingId,
        partnerId: partnerResponse.id,
        isFeatured: isFeatured,
      },
    },
  )

  return flattenBuildingPartnerPayload(buildingPartnerResponse)
}

/**
 * @param {PartnerRecord} partnerData
 * @return {Promise<PartnerRecord>}
 */
export async function updatePartner(partnerData) {
  const {isFeatured, buildingId, ...partnerInput} = partnerData

  const previous = await getBuildingPartnerForPartnerAndBuilding(partnerData.id, buildingId)

  // if the featured flag was changed, we update the BuildingPartner record
  if (!!previous.isFeatured !== partnerData.isFeatured) {
    await QueryHelper.Instance().executeMutation(
      gql`
        mutation UpdateBuildingPartner($input: UpdateBuildingPartnerInput!) {
          updateBuildingPartner(input: $input) {
            id
          }
        }
      `,
      {
        input: {
          id: previous.id,
          isFeatured: isFeatured,
        },
      },
    )
  }

  await QueryHelper.Instance().executeMutation(
    gql`
      mutation UpdatePartner($input: UpdatePartnerInput!) {
        updatePartner(input: $input) {
          id
        }
      }
    `,
    {
      input: partnerInput,
    },
  )

  return partnerData
}

/**
 * @param {string} partnerId
 * @param {string} buildingId
 * @return {Promise<void>}
 */
export async function deletePartner(partnerId, buildingId) {
  const record = await getBuildingPartnerForPartnerAndBuilding(partnerId, buildingId)

  if (record) {
    // we only remove the look up table. We'll keep the partner for posterity (for now)
    await QueryHelper.Instance().executeMutation(
      gql`
        mutation DeleteBuildingPartner($input: DeleteBuildingPartnerInput!) {
          deleteBuildingPartner(input: $input) {
            id
          }
        }
      `,
      {input: {id: record.id}},
    )
  }
}

/**
 * @param {string} partnerId
 * @param {number} order
 * @return {Promise<void>}
 */
export async function setPartnerOrder(partnerId, order) {
  return await QueryHelper.Instance().executeMutation(
    gql`
      ${PartnerFragment}
      mutation UpdatePartner($input: UpdatePartnerInput!) {
        updatePartner(input: $input) {
          ...PartnerFragment
        }
      }
    `,
    {
      input: {
        id: partnerId,
        order: order,
      },
    },
  )
}

/**
 * @param {string} residentId
 * @returns {Promise<*>}
 */

export async function listNotificationSettingsByResident(residentId) {
  return await QueryHelper.Instance().queryAll(
    gql`
      ${NotificationSettingFragment}
      query ListNotificationSettingsByResident(
        $residentId: ID
        $categoryMedium: ModelNotificationSettingByResidentCompositeKeyConditionInput
        $sortDirection: ModelSortDirection
        $filter: ModelNotificationSettingFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listNotificationSettingsByResident(
          residentId: $residentId
          categoryMedium: $categoryMedium
          sortDirection: $sortDirection
          filter: $filter
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            ...NotificationSettingFragment
          }
          nextToken
        }
      }
    `,
    {
      residentId: residentId,
    },
  )
}

/**
 * @param {*} input
 * @returns {Promise<any>}
 */
export async function updateNotificationSetting(input) {
  return await QueryHelper.Instance().queryOnce(
    gql`
      ${NotificationSettingFragment}
      mutation updateNotificationSetting($input: UpdateNotificationSettingInput!) {
        updateNotificationSetting(input: $input) {
          ...NotificationSettingFragment
        }
      }
    `,
    {
      input: input,
    },
  )
}

/**
 * @param {*} input
 * @returns {Promise<any>}
 */
export async function createNotificationSetting(input) {
  return await QueryHelper.Instance().queryOnce(
    gql`
      ${NotificationSettingFragment}
      mutation createNotificationSetting($input: CreateNotificationSettingInput!) {
        createNotificationSetting(input: $input) {
          ...NotificationSettingFragment
        }
      }
    `,
    {
      input: input,
    },
  )
}

/**
 * @param {string} buildingId
 * @param {string} searchTerm
 * @returns {QueryIterator}
 */
export function iterateSearchMessages(buildingId, searchTerm) {
  return QueryHelper.Instance().iterativeQuery(
    gql`
      ${MessageFragment}
      ${ChatMessageFragment}
      query SearchMessages($input: MessageContentSearchInput!, $nextToken: String, $limit: Int) {
        messageContentSearch(input: $input, nextToken: $nextToken, limit: $limit) {
          messages {
            ...MessageFragment
          }
          chatMessages {
            ...ChatMessageFragment
          }
          nextToken
        }
      }
    `,
    {
      limit: 100,
      input: {
        buildingId: buildingId,
        query: {match: searchTerm},
        type: 'All',
      },
    },
  )
}

/**
 * @param {string} buildingId
 * @param {string} searchTerm
 * @returns {QueryIterator}
 */
export function iterateSearchStaffNotes(buildingId, searchTerm) {
  return QueryHelper.Instance().iterativeQuery(
    gql`
      ${StaffNoteFragment}
      query SearchStaffNotes(
        $filter: SearchableStaffNoteFilterInput
        $sort: SearchableStaffNoteSortInput
        $limit: Int
        $nextToken: String
        $from: Int
      ) {
        searchStaffNotes(filter: $filter, sort: $sort, limit: $limit, nextToken: $nextToken, from: $from) {
          items {
            ...StaffNoteFragment
          }
          nextToken
        }
      }
    `,
    {
      filter: {
        content: {match: searchTerm},
        buildingId: {eq: buildingId},
      },
      sort: {
        field: 'createdAt',
        direction: 'desc',
      },
    },
  )
}

/**
 * @param {{eventId: string}} input
 * @returns {Promise<*>}
 */
export function getSocialEventRegistrants(input) {
  return QueryHelper.Instance().queryOnce(
    gql`
      query GetSocialEventRegistrants($input: GetSocialEventRegistrantsInput!) {
        getSocialEventRegistrants(input: $input) {
          registrants {
            residentId
            residentDisplayName
            partySize
            socialEventResponseId
            createdAt
          }
        }
      }
    `,
    {
      input: input,
    },
  )
}
