import ActionTypes from '../actions/types'
import Constant from '../components/Amenities/AmenitiesConstants'
import moment from 'moment'

const initialState = {
  amenitiesLookup: {},
  amenityReservationsLookup: {},
  reservationsRequiringApprovalCount: 0,
}

// --------------------------------------------------------------------------------
// State mutation functions:

function setAmenitiesData(state, amenitiesArr) {
  let newLookup = amenitiesArr.reduce((agr, a) => {
    agr[a.id] = a
    return agr
  }, {})

  return {...state, amenitiesLookup: newLookup}
}

function updateSingleAmenity(state, newAmenity) {
  return {...state, amenitiesLookup: {...state.amenitiesLookup, [newAmenity.id]: newAmenity}}
}

function setAmenityReservationsData(state, amenityReservationsArr) {
  let reservationsRequiringApprovalCount = 0
  let newLookup = amenityReservationsArr.reduce((agr, r) => {
    if (r.reservationState === Constant.STATE_REQUESTED && moment(r.startAt) > moment()) {
      reservationsRequiringApprovalCount++
    }
    agr[r.id] = r
    return agr
  }, {})

  return {...state, amenityReservationsLookup: newLookup, reservationsRequiringApprovalCount: reservationsRequiringApprovalCount}
}

function addNewAmenityReservation(state, newReservation) {
  return setAmenityReservationsData(state, [...Object.values(state.amenityReservationsLookup), newReservation])
}

function updateExistingAmenityReservation(state, updatedReservation) {
  return setAmenityReservationsData(state, [
    // keep all the other ones,
    ...Object.values(state.amenityReservationsLookup).filter(r => r.id !== updatedReservation.id),
    // add our updated one back to the list
    updatedReservation,
  ])
}

// --------------------------------------------------------------------------------

export default function (state = initialState, action) {
  switch (action.type) {
    case ActionTypes.AMENITIES_LOAD_ALL:
      return setAmenitiesData(state, action.payload)

    case ActionTypes.AMENITIES_LOAD_RESERVATIONS:
      return setAmenityReservationsData(state, action.payload)

    case ActionTypes.AMENITIES_UPDATE_SINGLE:
      return updateSingleAmenity(state, action.payload)

    case ActionTypes.AMENITY_RESERVATIONS_CREATE_NEW:
      return addNewAmenityReservation(state, action.payload)

    case ActionTypes.AMENITY_RESERVATIONS_UPDATE_EXISTING:
      return updateExistingAmenityReservation(state, action.payload)

    default:
      /* pass through */
      return state
  }
}
