import React from 'react'
import SubHeader from '../SubHeader'
import KYRGenericCardComponent from '../KYRGenericCardComponent'
import Timeline from '../Timeline'
import {KYRLoaderScreen} from '../../../assets/styles/layout'
import AbstractKYRScreen from './AbstractKYRScreen'
import {withStyles} from '@mui/styles'
import KYRFilter from '../KYRFilter'
import {FILTER_COPY} from '../KYRConstants'
import RXRButton from '../../RXRButton'
import {iterateResidentEventsByResident} from '../../../lib/queries'
import KYRInsights from '../KYRInsights'
import {MAP_PRIMARY_RECORD_TO_FILTER_LABEL} from '../KYRConstants'

const styles = theme => ({
  loader: {
    ...KYRLoaderScreen,
  },
  root: {
    overflowY: 'scroll',
    overflowX: 'hidden',
    height: 465,
  },
  timelineContainer: {
    marginTop: 12,
  },
})

class TimelineComponent extends AbstractKYRScreen {
  state = {
    // start off with all selected
    filterState: FILTER_COPY,
    isEventDataLoading: false,
    residentEventsData: [],
  }
  /** @type QueryIterator */
  iterator = null

  componentDidMount() {
    this.iterator = iterateResidentEventsByResident(this.props.navParams.residentId)
    this.loadMoreEvents()
  }

  onfilterStateChange = updatedfilterState => {
    this.setState({...this.state, filterState: updatedfilterState})
  }

  loadMoreEvents = () => {
    this.setState({isEventDataLoading: true})
    this.iterator.next().then(newData => {
      this.setState({residentEventsData: this.state.residentEventsData.concat(newData), isEventDataLoading: false})
    })
  }

  render() {
    const filteredAndSortedEvents =
      this.state.filterState.length > 0
        ? this.state.residentEventsData.filter(event =>
            this.state.filterState.includes(MAP_PRIMARY_RECORD_TO_FILTER_LABEL[event.primaryRecord]),
          )
        : []

    filteredAndSortedEvents.sort((a, b) => {
      // sort descending
      return a.createdAt === b.createdAt ? 0 : a.createdAt < b.createdAt ? 1 : -1
    })

    const KYRFilterButton = () => <KYRFilter onChange={this.onfilterStateChange} selectedFilters={this.state.filterState} />

    return (
      <KYRGenericCardComponent residentId={this.props.navParams.residentId}>
        <div className={this.props.classes.root}>
          <KYRInsights residentId={this.props.navParams.residentId} />
          <SubHeader title={'TIMELINE'} RightIconComponent={KYRFilterButton} />
          <div className={this.props.classes.timelineContainer}>
            <Timeline
              isEventDataLoading={this.state.isEventDataLoading}
              filterStateParent={this.state.filterState}
              residentEventsData={filteredAndSortedEvents}
              navigationType={'kyrWidget'}
            />

            {!this.iterator?.hasCompleted ? (
              <RXRButton type={RXRButton.TYPE_TEXT} onClick={this.loadMoreEvents} isLoading={this.state.isEventDataLoading}>
                Load more
              </RXRButton>
            ) : null}
          </div>
        </div>
      </KYRGenericCardComponent>
    )
  }
}

export default withStyles(styles)(TimelineComponent)
