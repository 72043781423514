export const PERMANENT_GUEST_EXPIRY_DATE_ISO = '3000-01-01T00:00:00.000Z'

export const CACHE_ActiveBuildingId = 'RXO_ACTIVE_BUILDING_ID'
export const CACHE_NanPanelOpenStatus = 'NavPanelOpenStatus'
export const CACHE_MuteSounds = 'MuteSounds'
export const CACHE_HidePopups = 'HidePopups'
export const CACHE_SuperForecaster = 'SuperForecaster'

export const MESSAGES_POLL_TIME_MS = 5000

export const PERMISSION_MESSAGES_READ = 'messagesRead'
export const PERMISSION_MESSAGES_WRITE = 'messagesWrite'
export const PERMISSION_HOUSEKEEPING_READ = 'servicesRead'
export const PERMISSION_HOUSEKEEPING_WRITE = 'servicesWrite'
export const PERMISSION_RESIDENT_PROFILES_READ = 'residentProfilesRead'
export const PERMISSION_RESIDENT_PROFILES_WRITE = 'residentProfilesWrite'
export const PERMISSION_SOCIAL_EVENTS_READ = 'socialEventsRead'
export const PERMISSION_SOCIAL_EVENTS_WRITE = 'socialEventsWrite'
export const PERMISSION_ANNOUNCEMENTS_READ = 'announcementsRead'
export const PERMISSION_ANNOUNCEMENTS_WRITE = 'announcementsWrite'
export const PERMISSION_SENSITIVE_INFORMATION_READ = 'sensitiveInformationRead'
export const PERMISSION_SETTINGS_CONTROL = 'settingsControl'

export const RESIDENT_STANDING_DELINQUENT = 'DELINQUENT'
export const RESIDENT_STANDING_WARNING = 'WARNING'

export const ALL_PERMISSIONS = [
  PERMISSION_MESSAGES_READ,
  PERMISSION_MESSAGES_WRITE,
  PERMISSION_HOUSEKEEPING_READ,
  PERMISSION_HOUSEKEEPING_WRITE,
  PERMISSION_RESIDENT_PROFILES_READ,
  PERMISSION_RESIDENT_PROFILES_WRITE,
  PERMISSION_SOCIAL_EVENTS_READ,
  PERMISSION_SOCIAL_EVENTS_WRITE,
  PERMISSION_ANNOUNCEMENTS_READ,
  PERMISSION_ANNOUNCEMENTS_WRITE,
  PERMISSION_SENSITIVE_INFORMATION_READ,
  PERMISSION_SETTINGS_CONTROL,
]
